import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import Step1 from '@/views/Step-1.vue'
import Step2 from '@/views/Step-2.vue'
import Step3 from '@/views/Step-3.vue'
import Step4 from '@/views/Step-4.vue'
import Step5 from '@/views/Step-5.vue'
import Step6 from '@/views/Step-6.vue'
import Result from '@/views/Result.vue'
import Share from '@/views/Share.vue'
import { answers } from '@/models/flavors'


const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { transition: 'fade' },
    },
    {
      path: '/step1',
      name: 'step1',
      component: Step1,
      meta: { 
        transition: 'fade'
      },
    },
    {
      path: '/step2',
      name: 'step2',
      component: Step2,
      meta: { 
        transition: 'fade'
      },
    },
    {
      path: '/step3',
      name: 'step3',
      component: Step3,
      meta: { 
        transition: 'fade'
      },
    },
    {
      path: '/step4',
      name: 'step4',
      component: Step4,
      meta: { 
        transition: 'fade'
      },
    },
    {
      path: '/step5',
      name: 'step5',
      component: Step5,
      meta: { 
        transition: 'fade'
      },
    },
    {
      path: '/step6',
      name: 'step6',
      component: Step6,
      meta: { 
        transition: 'fade'
      },
    },
    {
      path: '/result',
      name: 'result',
      component: Result,
      meta: { transition: 'fade' }
    },
    {
      path: '/share',
      name: 'share',
      component: Share,
      meta: { transition: 'fade' }
    }
  ]


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { 
      top: 0,
      behavior: 'smooth'
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'home' && !answers.gameStarted) next({ name: 'home' })
  else next()
})

export default router
