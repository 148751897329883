export interface Step4 {
    id: string;
}

export const step4List: Array<Step4> = [
  { id: '8'},
  { id: '9'},
  { id: '10'},
  { id: '11'},
  { id: '12'},
  { id: '13'},
  { id: '14'},
  { id: '15'},
  { id: '24'},
  { id: '25'},
  { id: '26'},
  { id: '27'},
  { id: '28'},
  { id: '29'},
  { id: '30'},
  { id: '31'},
  { id: '40'},
  { id: '41'},
  { id: '42'},
  { id: '43'},
  { id: '44'},
  { id: '45'},
  { id: '46'},
  { id: '47'},
  { id: '56'},
  { id: '57'},
  { id: '58'},
  { id: '59'},
  { id: '60'},
  { id: '61'},
  { id: '62'},
  { id: '63'}
]

export const direction = null