export interface Step1 {
    id: string;
}

export const step1List: Array<Step1> = [
  { id: '1'},
  { id: '3'},
  { id: '5'},
  { id: '7'},
  { id: '9'},
  { id: '11'},
  { id: '13'},
  { id: '15'},
  { id: '17'},
  { id: '19'},
  { id: '21'},
  { id: '23'},
  { id: '25'},
  { id: '27'},
  { id: '29'},
  { id: '31'},
  { id: '33'},
  { id: '35'},
  { id: '37'},
  { id: '39'},
  { id: '41'},
  { id: '43'},
  { id: '45'},
  { id: '47'},
  { id: '49'},
  { id: '51'},
  { id: '53'},
  { id: '55'},
  { id: '57'},
  { id: '59'},
  { id: '61'},
  { id: '63'}
]

export const direction = null