export interface Step3 {
    id: string;
}

export const step3List: Array<Step3> = [
  { id: '4'},
  { id: '5'},
  { id: '6'},
  { id: '7'},
  { id: '12'},
  { id: '13'},
  { id: '14'},
  { id: '15'},
  { id: '20'},
  { id: '21'},
  { id: '22'},
  { id: '23'},
  { id: '28'},
  { id: '29'},
  { id: '30'},
  { id: '31'},
  { id: '36'},
  { id: '37'},
  { id: '38'},
  { id: '39'},
  { id: '44'},
  { id: '45'},
  { id: '46'},
  { id: '47'},
  { id: '52'},
  { id: '53'},
  { id: '54'},
  { id: '55'},
  { id: '60'},
  { id: '61'},
  { id: '62'},
  { id: '63'}
]

export const direction = null