export interface Step5 {
    id: string;
}

export const step5List: Array<Step5> = [
  { id: '16'},
  { id: '17'},
  { id: '18'},
  { id: '19'},
  { id: '20'},
  { id: '21'},
  { id: '22'},
  { id: '23'},
  { id: '24'},
  { id: '25'},
  { id: '26'},
  { id: '27'},
  { id: '28'},
  { id: '29'},
  { id: '30'},
  { id: '31'},
  { id: '48'},
  { id: '49'},
  { id: '50'},
  { id: '51'},
  { id: '52'},
  { id: '53'},
  { id: '54'},
  { id: '55'},
  { id: '56'},
  { id: '57'},
  { id: '58'},
  { id: '59'},
  { id: '60'},
  { id: '61'},
  { id: '62'},
  { id: '63'}
]

export const direction = null