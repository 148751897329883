export interface Step6 {
    id: string;
}

export const step6List: Array<Step6> = [
  { id: '32'},
  { id: '33'},
  { id: '34'},
  { id: '35'},
  { id: '36'},
  { id: '37'},
  { id: '38'},
  { id: '39'},
  { id: '40'},
  { id: '41'},
  { id: '42'},
  { id: '43'},
  { id: '44'},
  { id: '45'},
  { id: '46'},
  { id: '47'},
  { id: '48'},
  { id: '49'},
  { id: '50'},
  { id: '51'},
  { id: '52'},
  { id: '53'},
  { id: '54'},
  { id: '55'},
  { id: '56'},
  { id: '57'},
  { id: '58'},
  { id: '59'},
  { id: '60'},
  { id: '61'},
  { id: '62'},
  { id: '63'}
]

export const direction = null