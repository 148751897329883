export interface Step2 {
    id: string;
}

export const step2List: Array<Step2> = [
  { id: '2'},
  { id: '3'},
  { id: '6'},
  { id: '7'},
  { id: '10'},
  { id: '11'},
  { id: '14'},
  { id: '15'},
  { id: '18'},
  { id: '19'},
  { id: '22'},
  { id: '23'},
  { id: '26'},
  { id: '27'},
  { id: '30'},
  { id: '31'},
  { id: '34'},
  { id: '35'},
  { id: '38'},
  { id: '39'},
  { id: '42'},
  { id: '43'},
  { id: '46'},
  { id: '47'},
  { id: '50'},
  { id: '51'},
  { id: '54'},
  { id: '55'},
  { id: '58'},
  { id: '59'},
  { id: '62'},
  { id: '63'}
]


export const direction = null